<template>
  <div>
    <div>
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="名称" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space style="margin-top: 4px">
                <Button type="primary" @click="query">查询</Button>
                <Button @click="reset">重置</Button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <Button
                type="primary"
                @click="$router.push('/drainage/emergancy/riskTraining/add')"
                >新建</Button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding size="large" />

      <a-table
        :dataSource="list"
        @change="onChange"
        :loading="loading"
        rowKey="id"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column
          key="name"
          title="主题"
          width="200px"
          :ellipsis="true"
          data-index="name"
        />
        <a-table-column
          key="content"
          title="内容"
          width="200px"
          :ellipsis="true"
          data-index="content"
        />
        <a-table-column key="lecture" title="讲师" data-index="lecture" />
        <a-table-column
          key="number"
          width="80px"
          title="人数"
          data-index="number"
        />
        <a-table-column key="time" title="时间" data-index="time" />
        <a-table-column
          key="participant"
          title="参与人员"
          width="200px"
          :ellipsis="true"
          data-index="participant"
        />

        <a-table-column key="control" title="操作" width="100px" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="toEdit(text)">编辑</a>
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/risk/riskTraining";

export default {
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({ id: text.id }).then(() => {
            that.getList();
          });
        },
      });
    },
    toEdit(item) {
      this.$router.push({
        path: "/drainage/emergancy/riskTraining/edit",
        query: { detail: item },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>
